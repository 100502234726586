import React from "react"
import { Link } from "gatsby"
import { Helmet } from "react-helmet"

import Layout from "../components/layout"

export default (props) => {
  return (
    <Layout>
      <Helmet bodyAttributes={{ class: 'wit-cantfind' }} />
      <div className="body make__grid">
        <h1>Sorry - I can't find the page you're looking for...</h1>  
        <Link to="/" className="btn primary">Back to the homepage</Link>
      </div>
  </Layout>
  )
}
